export enum URL {
  HOME = '/',
  LOGIN = '/login',
  REGISTER = '/signup',
  VERIFY_ACCOUNT = '/verify_account',
  VERIFY_EMAIL = '/verify_mail',
  ASSETS = '/assets',
  PIPELINES = '/pipelines',
  WORKFLOWS = '/workflows',
  SETTINGS = '/settings',
  EMBEDED = '/embeded',
  PUBLISHED = '/published',
  CLI = '/cli',
  WEBHOOKS = '/webhooks',
  USAGE_STATISTICS = '/usageStatistics',
  FORGOT_PASSWORD = '/forgotPassword',
  API_TOKENS = '/api_tokens',
  CHOOSE_PLAN = '/choose_plan',
  PAYMENT_INFO = '/choose_plan/payment_info',
  SUMMARY = '/choose_plan/payment_info/summary',
  SUCCESS = '/choose_plan/success',
  ADMIN = '/admin',
  COMPLETE_REGISTRATION = '/complete_registration',
  SCALE_WIDGET = '/scale_widget',
  PLUGINS = '/plugins',
  DESTROY_SESSION = '/destroy_session',
}

export const urlHelper = {
  home: () => URL.HOME,
  login: () => URL.LOGIN,
  register: () => URL.REGISTER,
  verifyAccount: () => URL.VERIFY_ACCOUNT,
  verifyEmail: () => URL.VERIFY_EMAIL,
  assets: () => URL.ASSETS,
  pipelines: () => URL.PIPELINES,
  workflows: () => URL.WORKFLOWS,
  settings: () => URL.SETTINGS,
  embeded: (rapidId?: number) => {
    if (rapidId === undefined) {
      return URL.EMBEDED;
    }
    return `${URL.EMBEDED}/rapid/${rapidId}`;
  },
  published: () => URL.PUBLISHED,
  cli: () => URL.CLI,
  plugins: () => URL.PLUGINS,
  webhooks: () => URL.WEBHOOKS,
  usageStatistics: () => URL.USAGE_STATISTICS,
  forgotPassword: () => URL.FORGOT_PASSWORD,
  apiTokens: () => URL.API_TOKENS,
  plan: {
    choosePlan: () => URL.CHOOSE_PLAN,
    paymentInfo: () => URL.PAYMENT_INFO,
    summary: () => URL.SUMMARY,
    success: () => URL.SUCCESS,
  },
  admin: () => URL.ADMIN,
  completeRegistration: () => URL.COMPLETE_REGISTRATION,
  scaleWidget: () => URL.SCALE_WIDGET,
  destroySession: () => URL.DESTROY_SESSION,
};
