'use client';

import * as Sentry from '@sentry/nextjs';
import { Button, Card, Center, Group, MantineProvider, Stack, Title } from '@mantine/core';
import { useEffect } from 'react';
import { urlHelper } from '@/utils';
import Link from 'next/link';
import { mantineThemeOverride } from './theme';

export default function GlobalError({ error }: { error: Error & { digest?: string } }) {
  useEffect(() => {
    Sentry.captureException(error);
    console.error({ error });
  }, [error]);

  return (
    <html>
      <body>
        <main>
          <MantineProvider theme={mantineThemeOverride}>
            <Center mt='lg'>
              <Card w='fit-content'>
                <Stack>
                  <Title ta='center' order={2} size='h5'>
                    An unexpected error occurred!
                  </Title>
                  <Group justify='center' gap='sm'>
                    <Button miw='10em' component={Link} href={urlHelper.home()}>
                      Back to Home
                    </Button>
                  </Group>
                </Stack>
              </Card>
            </Center>
          </MantineProvider>
        </main>
      </body>
    </html>
  );
}
